body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



@media print {
  body * {
    visibility: hidden;
  }
  h3 footer {
    page-break-after: always;
  }
  .layout-invoice-content {
    position: absolute;
    left: 50;
    top: 0;
  }
  .layout-invoice-content * {
    visibility: visible;
    box-shadow: none;
    color: black !important;
    color: black;
    border-color: #e4e4e4 !important;
    background-color: transparent !important;
  }
  .job-card-content {
    position: absolute;
    left: 50;
    top: 0;
  }
  .job-card-content * {
    visibility: visible;
    box-shadow: none;
    color: black;
    border-color: #e4e4e4 !important;
    background-color: transparent !important;
  }
}