@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@500&family=Rajdhani&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
}
.bangla-text {
  font-family: "Hind Siliguri", sans-serif !important;
}
html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

/* navbar style start  */
.main-nav {
  width: 100%;
  height: 7rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  /* padding: auto 10rem; */
  display: grid;
  grid-template-columns: 10rem 1fr 2fr 1fr 10rem;
}

.logo {
  display: grid;
  grid-column: 2/3;
  /* background-color: antiquewhite; */
  justify-content: start;
  align-items: center;
}

.logo h2 {
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#eee, rgb(69, 57, 248));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo h2 span {
  font-size: 3.5rem;
}

.menu-link {
  grid-column: 3/4;
  /* background-color: aquamarine; */
}

.menu-link ul {
  height: 7rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.menu-link ul li {
  font-size: 1.8rem;
}

.menu-link ul li a {
  color: rgba(0, 0, 3, 0.8);
  transition: 0.5s;
}
.menu-link ul li:hover > a {
  transform-origin: left;
  color: rgba(0, 0, 3, 1);
  text-decoration: underline;
  transition: 0.5s;
}

.social-media {
}

.social-media ul {
  height: 7rem;
  display: grid;
  grid-template-columns: 3fr repeat(3, 1fr);
  align-items: center;
}

.social-media ul li:first-child {
  grid-column: 2/3;
}

.social-media ul li {
  font-size: 1.8rem;
}

.social-media .hamburger-menu {
  display: none;
}

.facebook {
  color: #3b5998;
}

.instagram {
  color: #c32aa3;
}

.youtube {
  color: #ff0000;
}

/* hero section   */

.hero-section {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.heroDetails {
  color: #0db960;
}
.heroDetails-second {
  color: #7f2e8d;
}
.heroDetails-third {
  color: #2a82ce;
}

.hero-section p {
  font-size: 3rem;
  text-transform: capitalize;
}

.hero-section h1 {
  font-size: 5rem;
  text-transform: uppercase;
  text-align: center;
}

/* home page css */

/* hero section */
.first-div {
  background-image: url("./images/scholarship-2-removebg-preview.png");
  height: 500px;
  width: 600px;
  background-size: contain;
  background-repeat: no-repeat;
}
.second-div {
  background-image: url("./images/s-2022-removebg-preview.png");
  height: 500px;
  width: 600px;
  background-size: contain;
  background-repeat: no-repeat;
}
.third-div {
  background-image: url("./images/seraat.png");
  height: 500px;
  width: 600px;
  background-size: contain;
  background-repeat: no-repeat;
}

/* middle section */

.middle-div-one {
  background-image: url("./images/Untitled-1_703368e4-8b89-48eb-832e-13c3d740cf28.jpeg");
  height: 500px;
  width: 570px;
  background-size: contain;
  background-repeat: no-repeat;
}

.middle-div-two {
  background-image: url("./images/banner02.jpeg");
  height: 500px;
  width: 570px;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  /* Adjust based on your breakpoint */
  .middle-div-one,
  .middle-div-two {
    width: 100%; /* Make the width full for smaller screens */
    height: auto; /* Allow the height to adjust proportionally */
  }
}

.dashboard {
  background-color: #62ab2b;
}

/* =-------- */
@media only screen and (max-width: 639px) {
  .google-phone-responsib {
    margin-top: 50px;
  }
}

.from-top-text {
  color: #242f6c;
}
/* card */
/* card */
/* card */
.card-text-top {
  color: #242f6c;
}
.icon-class1 {
  color: rgb(38, 201, 212);
  margin-top: 7px;
}
.icon-class1:hover {
  color: rgb(57, 242, 255);
}
.icon-class2 {
  color: #ffc4c4;
  margin-top: 7px;
}
.icon-class2:hover {
  color: #db8d8d;
}
.icon-class3 {
  color: #c1b3fa;
  margin-top: 7px;
}
.icon-class3:hover {
  color: #9384ce;
}
.icon {
  text-align: center;
  margin-left: 90px;
}
.office-class {
  text-align: start;
  margin-left: -10px;
}

@media only screen and (max-width: 639px) {
  .office-class {
    text-align: start;
    margin-left: 1px;
    font-size: 24px;
  }
}

/* responsive css style  */
@media (max-width: 1080px) {
  .main-nav {
    padding: 5px;
    height: 8rem;
    grid-template-columns: 2rem 3fr 3fr 1fr 2rem;
  }

  .logo,
  .menu-link ul,
  .social-media ul {
    padding: 5px;
    height: 8rem;
  }
}

/* responsive css style  */
@media (max-width: 998px) {
  .main-nav {
    height: 7rem;
    grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
  }

  .menu-link {
    display: none;
  }

  .logo,
  .social-media ul {
    height: 7rem;
  }

  .mobile-menu-link {
    grid-column: 2/4;
    position: relative;
    z-index: 99;
  }

  .mobile-menu-link {
    background-color: white;
    height: 20rem;
    display: grid;
    grid-column: 2/5;
    align-items: center;
    padding-left: 3rem;
    transition: all 2s linear;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .mobile-menu-link ul {
    height: 20rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: start;
  }

  .mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }

  .social-media {
    grid-row: 1/2;
    grid-column: 3/5;
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
  }

  .social-media .social-media-desktop {
    height: 0;
    display: none;
  }

  .social-media {
    height: 7rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 798px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
  }

  .logo,
  .social-media ul {
    height: 6rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 520px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
  }

  .logo,
  .social-media ul {
    height: 6rem;
  }

  .logo h2 {
    font-size: 2rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }

  /* hero section  */

  .hero-section h1 {
    font-size: 3.8rem;
  }
}

.bg-newbuttonColor {
  background-color: #62ab00;
}

.bg-editbuttonColor {
  background-color: #3f51b5;
}

/* slider */

.step-finish {
  .ant-steps-item-icon {
    background-color: green;
    border-color: green;
  }
  .ant-steps-item-title,
  .ant-steps-item-subtitle {
    color: green;
  }
}
.home-transition{
   white-space: nowrap;
   animation: 60s linear 0s infinite normal none running scrollText;
   -webkit-animation: 60s linear 0s infinite normal none running scrollText;
}

.step-process {
  .ant-steps-item-icon {
    background-color: blue;
    border-color: blue;
  }
  .ant-steps-item-title,
  .ant-steps-item-subtitle {
    color: blue;
  }
}

.step-wait {
  .ant-steps-item-icon {
    background-color: gray;
    border-color: gray;
  }
  .ant-steps-item-title,
  .ant-steps-item-subtitle {
    color: gray;
  }
}

.step-error {
  .ant-steps-item-icon {
    background-color: red;
    border-color: red;
  }
  .ant-steps-item-title,
  .ant-steps-item-subtitle {
    color: red;
  }
}

/* Enroll Button */
.enroll-button {
  background-color: #80CC37;
  border-color: #80CC37;
  color: white;

}

/* Details Button */
.details-button {
  border-color: #2D6A3F;
  color: #2D6A3F;
  background-color: transparent;

}



